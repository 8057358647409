import {
  TAvailableNestThermostatMode,
  TIntegration,
  TIntegrationEntities,
  TIntegrationFilter,
  TIntegrationLinks,
  TIntegrationStatic,
} from "src/data/integrations/types/integrationTypes"
import { langKeys } from "src/i18n/langKeys"
import AutomationIcon from "src/ui/icons/automation.svg"
import HVACIcon from "src/ui/icons/climate-control.svg"
import GXPIcon from "src/ui/icons/guest-experience-platforms.svg"
import OTAIcon from "src/ui/icons/online-travel-agency.svg"
import PMSIcon from "src/ui/icons/property-management-system.svg"
import LockIcon from "src/ui/icons/smart-lock.svg"
import { TAlert } from "src/ui/MBanner/MBanner"

/**
 * Used to determine if the integration is an inbound integration,
 * meaning that the connection is done from the external party, not from
 * our side
 * @returns true if the integration supports the external_link operation
 */
export function isExternalLinkIntegration(integration: TIntegration): boolean {
  return !!integration.operations?.some(
    (operation) => operation.type === "external_link"
  )
}

const ota: TIntegrationFilter = {
  id: "ota",
  name: (t) => t(langKeys.integrations_group_ota_title),
  value: "ota",
  unique: true,
  icon: OTAIcon,
}

const pms: TIntegrationFilter = {
  id: "pms",
  name: (t) => t(langKeys.integrations_group_pms_title),
  value: "pms",
  unique: true,
  icon: PMSIcon,
}

const customAutomation: TIntegrationFilter = {
  id: "automation",
  name: (t) => t(langKeys.integrations_group_custom_title),
  value: "automation",
  unique: true,
  icon: AutomationIcon,
}

const smartLock: TIntegrationFilter = {
  id: "lock",
  name: (t) => t(langKeys.integrations_group_lock_title),
  value: "lock",
  unique: true,
  icon: LockIcon,
}

const hvac: TIntegrationFilter = {
  id: "hvac",
  name: (t) => t(langKeys.integrations_group_hvac_title),
  value: "hvac",
  unique: true,
  icon: HVACIcon,
}

const gxp: TIntegrationFilter = {
  id: "guest_experience",
  name: (t) => t(langKeys.integrations_group_gxp_platforms_title),
  value: "guest_experience",
  unique: true,
  icon: GXPIcon,
}

export const integrationFiltersList: TIntegrationFilter[] = [
  ota,
  pms,
  customAutomation,
  smartLock,
  hvac,
  gxp,
]

export function isStaticIntegration(
  i: TIntegrationStatic | TIntegration
): i is TIntegrationStatic {
  return (i as TIntegrationStatic).static === true
}

export function getConnectedStateColor({
  linksCount,
  isExternal,
  isBrokenIntegration,
  hasBrokenLinks,
}: {
  linksCount: number
  isExternal: boolean
  isBrokenIntegration: boolean
  hasBrokenLinks: boolean
}): TAlert {
  if (isBrokenIntegration) {
    return "error"
  }

  if ((isExternal || linksCount > 0) && !hasBrokenLinks) {
    return "good"
  }

  return "warning"
}

export function showIntegrationProgressData({
  homesCount,
  isExternal,
  isConfigured,
  error,
}: {
  homesCount: number
  isExternal: boolean
  isConfigured: boolean
  error: boolean
}) {
  return homesCount > 0 && !isExternal && isConfigured && !error
}

export function getCommonAvailableNestModes(
  devices: TIntegrationEntities[number]["devices"]
) {
  const modes = Array.from(
    new Set(devices?.flatMap((d) => d.available_modes || []))
  )

  const commonModes = modes.filter((mode) =>
    devices?.every((d) => d.available_modes?.includes(mode))
  )

  return commonModes
}

export function getDefaultNestMode(
  availableNestModes: TAvailableNestThermostatMode[]
): TAvailableNestThermostatMode {
  if (availableNestModes?.includes("heat")) {
    return "heat"
  }

  if (availableNestModes?.includes("cool")) {
    return "cool"
  }

  return "heatcool"
}

export function filterUnlinkedIntegrationEntites(
  integrationEntities: TIntegrationEntities,
  integrationLinks: TIntegrationLinks
) {
  return integrationEntities.filter(
    (entity) =>
      !integrationLinks.find((link) => entity.entity_id === link.entity_id)
  )
}

export function checkBrokenIntegration(integration: TIntegration) {
  return !!integration.connection_status?.some((i) => i.status === "broken")
}
